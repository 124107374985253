import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Changelog from '../views/Changelog.vue';
import Splash from '../views/Splash.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/map',
    name: 'Map',
    component: Home,
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
