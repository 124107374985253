<template>
  <div style="overflow-x:hidden;width:100%;height:100%;">
    <div :class="mobileMode ? 'mobile-hero' : 'hero'"></div>
    <div :class="mobileMode ? 'mobile-wrapper' : 'wrapper'">
      <p class="title">The Economic Impact of Renewable Energy and Energy Storage in Rural Texas</p>
      <p class="subtext">
        Click
        <router-link class="link" :to="{ name: 'Map' }">here</router-link>
        to access the interactive map.
      </p>
      <p class="subtext">
        Click
        <a class="link" target="_blank" href="https://static1.squarespace.com/static/652f1dc02732e6621adb2a3a/t/654c16f5cdf7303d942ceffc/1699485429992/Economic-Impact-of-Renewable-Energy_JAN2023.pdf">here</a>
        to read the full study.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    mobileMode() {
      return window.innerWidth < 720;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Myriad";
  src: url("/myriad_pro_light.otf");
}
@font-face {
  font-family: "Minion";
  src: url("/minion_regular.otf");
}
.hero {
  position: fixed;
  background-image: url("/splash.jpg");
  background-size: cover;
  width: 120%;
  height: 120%;
  margin-left: -20%;
}
.mobile-hero {
  position: absolute;
  background-image: url("/splash.jpg");
  background-size: cover;
  width: 600%;
  height: 300%;
  margin-left: -500%;
  margin-top: -200%;
}
.link {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}

.wrapper {
  position: absolute;
  text-align: center;
  color: #333;
  width: 100%;
}

.mobile-wrapper {
  position: absolute;
  text-align: center;
  color: #333;
  width: 100%;
}

.wrapper .title {
  font-size: 5rem;
  font-family: "Myriad";
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 0;
  margin-bottom: 20px;
}

.wrapper .subtext {
  font-size: 1.5rem;
  font-family: "Minion";
}

.mobile-wrapper .title {
  font-size: 1.5rem;
  font-family: "Myriad";
  margin-left: 20%;
  margin-right: 20%;
}

.mobile-wrapper .subtext {
  font-size: 1rem;
  font-family: "Minion";
}

</style>
