<template>
  <div>
    <div class="header" :style="{height: mobileMode ? '30px' : '60px'}">
      <a
        href="/changelog.html"
        style="text-decoration:none;color:#0099cc;margin-left:8px;">
        Change Log
      </a>
      <div style="display:flex;flex-grow:1;justify-content:center;align-items:center;height:100%;">
        <a href="https://poweralliance.org" style="height:100%;" target="_blank">
          <img src="@/assets/apa_logo.png"
            :style="{
              height: mobileMode ? '60%' : '100%',
              'margin-right': '16px'
            }" />
        </a>

        <a href="https://www.conservativetexansforenergyinnovation.org" style="height:100%;" target="_blank">
          <img src="@/assets/ctei_logo.png"
            :style="{
              height: mobileMode ? '60%' : '100%',
              'margin-right': '16px'
            }" />
        </a>
        <a href="https://www.txbiz.org" style="height:100%;" target="_blank">
          <img src="@/assets/tab_logo.jpg"
            :style="{
              height: mobileMode ? '60%' : '100%',
              'margin-right': '16px'
            }" />
        </a>
      </div>
    </div>
    <div>
      <div :style="{
        position: 'absolute',
        width: mobileMode ? '20%' : '25%',
        'font-size': mobileMode ? '10px' : false,
        'z-index': 9000,
        right: 0,
        background: '#d5d5d5',
        'text-align': 'left',
        padding: mobileMode ? '10px' : '16px',
        'border-radius': '8px',
        transform: 'translate(-14px, 70px)'
      }">
        <label :style="{
          'font-weight': 600,
          'font-size': mobileMode ? '10px' : '13px'
          }">Choose one:</label>
        <select :style="{
          width: '100%',
          'margin-bottom': '16px',
          'font-size': mobileMode ? '9px' : false
          }" v-model="region">
          <option value="senateTX">TX Senate</option>
          <option value="houseTX">TX House</option>
          <option value="county">TX County</option>
          <option value="houseUS">US House</option>
          <option value="senateUS">US Senate (Statewide)</option>
        </select>

        <label :style="{
          'font-weight': 600,
          'font-size': mobileMode ? '10px' : '13px'
          }">Choose tech:</label>
        <select :style="{
          width: '100%',
          'margin-bottom': '16px',
          'font-size': mobileMode ? '9px' : false
          }" v-model="tech">
          <option value="wind">Wind</option>
          <option value="solar">Solar</option>
          <option value="storage">Storage</option>
        </select>

        <label :style="{
          'font-weight': 600,
          'font-size': mobileMode ? '10px' : '13px'
          }">Choose one:</label>
        <select :style="{
          width: '100%',
          'margin-bottom': '16px',
          'font-size': mobileMode ? '9px' : false
          }" v-model="buildStatus">
          <option value="built">Built</option>
          <option value="built+expected">Built + Expected</option>
          <option value="expected">Expected</option>
        </select>

        <label :style="{
          'font-weight': 600,
          'font-size': mobileMode ? '10px' : '13px'
          }">Choose one:</label>
        <select :style="{
          width: '100%',
          'margin-bottom': '16px',
          'font-size': mobileMode ? '9px' : false
          }" v-model="pricing">
          <option value="cap">Capacity (MW)</option>
          <option value="taxes">Local Taxes (est. $M)</option>
          <option value="landowner">Landowner Payments (est. $M)</option>
        </select>

        <label v-show="region !== 'senateUS'" :style="{
          'font-weight': 600,
          'font-size': mobileMode ? '10px' : '13px'
          }">Find a {{ region === 'county' ? 'county' : 'district' }}</label>
        <select :style="{
          width: '100%',
          'margin-bottom': '16px',
          'font-size': mobileMode ? '9px' : false
          }" v-model="selectedRegion[region]" v-show="region !== 'senateUS'">
          <option value="">Select one</option>
          <option v-for="zone of activeData"
            :key="zone.id"
            :value="zone.id">
            {{ zone.properties.name }}{{ region === 'county' ? '' : ', ' + getRegionRepInfo(zone) }}
          </option>
        </select>
        <a href="https://wrm.capitol.texas.gov/home" target="_blank">
          Look up a Lawmaker/District
        </a>
      </div>
      <Legend
        :style="{
          position: 'absolute',
          'z-index': 9000,
          left: 0,
          transform: 'translate(14px, 140%)',
          'font-size': mobileMode ? '9px' : (laptopMode ? '13px' : false),
          width : mobileMode ? '80px' : '120px'
        }"
        :title="tech.substr(0, 1).toUpperCase() + tech.substr(1) + ' '
          + (pricing === 'cap' ? '(MW)' : '($M)')"
        :colors="colors"
        />
      <LMap v-if="mapHeight" :style="{ width: '100%', height: mapHeight }"
        v-bind="mapAttrs">
        <LPolygon v-for="zone of activeData"
          v-bind="polygonAttrs"
          color="black"
          :fill-color="getColor(getCost(zone, tech))"
          :key="zone.id"
          :lat-lngs="zone.geometry.coordinates"
          :ref="`${region}-${zone.id}`"
          @click="onClick(zone)"
          @mouseover="mouseover($event)"
          @mouseout="mouseout($event, zone)">
          <LTooltip>
            {{ zone.properties.name }}<br />
            {{ getRegionRepInfo(zone) }}<br v-if="getRegionRepInfo(zone)" />
            Wind: {{ getTooltipData(zone, 'wind') }}<br />
            Solar: {{ getTooltipData(zone, 'solar') }}<br />
            Storage: {{ getTooltipData(zone, 'storage') }}<br />
          </LTooltip>
        </LPolygon>
      </LMap>
    </div>

    <Modal v-show="clickedRegion" @close="clickedRegion = null; modalDownloadAvailable = 'yes'">
      <div slot="header">{{ modalHeaderContent }}</div>
      <div slot="body">
        <p v-if="modalDownloadAvailable === 'loading'">Checking for projects...</p>
        <p v-if="!modalDownloadAvailable">No Projects for this region</p>
        <p v-if="modalDownloadAvailable === 'error'">
          Error loading project data. Please try again in a moment.
        </p>
        <a v-if="modalDownloadAvailable === 'yes'"
          :href="modalHref"
          :download="modalDownload">
          {{ modalLinkText }}
        </a>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  LMap,
  LPolygon,
  LTooltip,
} from 'vue2-leaflet';

import Legend from '../components/Legend.vue';
import Modal from '../components/Modal.vue';

export default {
  components: {
    LMap,
    LPolygon,
    LTooltip,
    Legend,
    Modal,
  },
  data() {
    return {
      regionData: {
        county: [],
        houseTX: [],
        houseUS: [],
        senateTX: [],
        senateUS: [],
      },
      buildStatus: 'built',
      pricing: 'cap',
      region: 'senateTX',
      tech: 'wind',
      mapHeight: 0,
      selectedRegion: {
        county: '',
        houseTX: '',
        houseUS: '',
        senateTX: '',
        senateUS: '',
      },
      clickedRegion: null,
      modalDownloadAvailable: 'loading',
    };
  },
  computed: {
    activeData() {
      const data = this.regionData[this.region];
      const re = /(.*) (.*) \([DR]\)/g;
      if (this.region === 'county') {
        data.sort((a, b) => (a.properties.name > b.properties.name ? 1 : -1));
      } else {
        data.sort((a, b) => {
          re.lastIndex = 0;
          const name1 = re.exec(a.properties.name)[2];
          re.lastIndex = 0;
          const name2 = re.exec(b.properties.name)[2];
          return (name1 > name2 ? 1 : -1);
        });
      }
      return data;
    },
    mobileMode() {
      return window.innerWidth < 720;
    },
    laptopMode() {
      return !this.mobileMode && window.innerWidth < 1500;
    },
    colors() {
      return [
        { range: [0.01, 325], hex: '#2166ac' },
        { range: [326, 650], hex: '#4393c3' },
        { range: [651, 975], hex: '#92c5de' },
        { range: [976, 1300], hex: '#d1e5f0' },
        { range: [1301, 1625], hex: '#f7f7f7' },
        { range: [1626, 1950], hex: '#fddbc7' },
        { range: [1951, 2275], hex: '#f4a582' },
        { range: [2276, 2600], hex: '#d6604d' },
        { range: [2601, Infinity], hex: '#b2182b' },
        { range: [-Infinity, 0], hex: 'lightgray' },
      ];
    },
    mapAttrs() {
      return {
        center: [31.9686, -99],
        zoom: this.mobileMode ? 5 : 6,
      };
    },
    polygonAttrs() {
      return {
        color: 'black',
        fillOpacity: 0.75,
        opacity: 0.75,
        smoothFactor: 0.5,
        weight: 1,
      };
    },
    selectedCounty() {
      return this.selectedRegion.county;
    },
    selectedHouseTX() {
      return this.selectedRegion.houseTX;
    },
    selectedHouseUS() {
      return this.selectedRegion.houseUS;
    },
    selectedSenateTX() {
      return this.selectedRegion.senateTX;
    },
    modalHeaderContent() {
      if (!this.clickedRegion) return '';
      const zone = this.clickedRegion;
      const text = this.region === 'county' ? `${zone.properties.name} County` : this.getRegionRepInfo(zone);
      return `Download Data for ${text}`;
    },
    modalHref() {
      let location;
      const zone = this.clickedRegion;
      if (!zone) { return ''; }
      if (this.region === 'senateTX') {
        location = `/tx-senate-projects/tx-senate-district-${zone.properties.tx_senate_num}-projects.pdf`;
      } else if (this.region === 'houseTX') {
        location = `/tx-house-projects/tx-house-district-${zone.properties.tx_house_num}-projects.pdf`;
      } else if (this.region === 'houseUS') {
        location = `/us-house-projects/us-house-district-${zone.properties.us_house_num}-projects.pdf`;
      } else if (this.region === 'county') {
        location = `/tx-county-projects/tx-county-${zone.properties.name}-projects.pdf`;
      } else if (this.region === 'senateUS') {
        location = '/tx-state-renewable-projects.pdf';
      }
      location = encodeURI(location);
      return location;
    },
    modalDownload() {
      return this.modalHref.split('/').slice(-1);
    },
    modalLinkText() { return 'Download'; },
  },
  async created() {
    const county = await fetch('/county_data.geojson').then((response) => response.json());
    const houseTX = await fetch('/tx_house_data.geojson').then((response) => response.json());
    const houseUS = await fetch('/tx_us_house_data.geojson').then((response) => response.json());
    const senateTX = await fetch('/tx_senate_data.geojson').then((response) => response.json());
    const senateUS = await fetch('/tx_state.geojson').then((response) => response.json());
    this.regionData.county = county.features;
    this.regionData.houseTX = houseTX.features;
    this.regionData.houseUS = houseUS.features;
    this.regionData.senateTX = senateTX.features;
    this.regionData.senateUS = senateUS.features;
  },
  mounted() {
    this.mapHeight = `${window.innerHeight - 20}px`;
  },
  methods: {
    onClick(zone) {
      this.clickedRegion = zone;
      this.modalDownloadAvailable = 'loading';
      fetch(this.modalHref, { method: 'HEAD' })
        .then((response) => {
          if (response.ok) {
            this.modalDownloadAvailable = 'yes';
          } else if (response.status === 404) {
            this.modalDownloadAvailable = false;
          } else {
            this.modalDownloadAvailable = 'error';
          }
        })
        .catch(() => { this.modalDownloadAvailable = 'error'; });
    },
    mouseover(e) {
      e.target.setStyle({ color: 'white', weight: 2 });
      e.target.bringToFront();
      const els = this.$refs[`${this.region}-${this.selectedRegion[this.region]}`];
      if (els) {
        const el = els[0];
        el.mapObject.bringToFront();
      }
    },
    mouseout(e, zone) {
      if (this.selectedRegion[this.region] !== zone.id) {
        e.target.setStyle({ color: 'black', weight: 1 });
      }
      const els = this.$refs[`${this.region}-${this.selectedRegion[this.region]}`];
      if (els) {
        const el = els[0];
        el.mapObject.bringToFront();
      }
    },
    getCost(region, tech) {
      const expected = region.properties[`${tech}_${this.pricing}_expect`] || 0;
      const built = region.properties[`${tech}_${this.pricing}_exist`] || 0;
      return {
        built: Math.floor(built * 100) / 100,
        expected: Math.floor(expected * 100) / 100,
        'built+expected': (Math.floor(built * 100) + Math.floor(expected * 100)) / 100,
      }[this.buildStatus];
    },
    getColor(cost) {
      return this.colors
        .find((color) => color.range[0] <= cost && color.range[1] >= cost)
        .hex;
    },
    getRegionRepInfo(region) {
      const p = region.properties;
      const info = p.us_house_num || p.tx_senate_num || p.tx_house_num;
      return {
        senateTX: `Texas Senate District ${info}`,
        senateUS: 'Texas',
        houseTX: `Texas House District ${info}`,
        houseUS: `US House ${info}`,
        county: '',
      }[this.region];
    },
    getTooltipData(region, tech) {
      const cost = this.getCost(region, tech);
      const prefix = this.pricing === 'cap' ? '' : '$';
      let suffix = 'M';
      if (this.pricing === 'cap') {
        suffix = ' MW';
      }
      return `${prefix}${cost}${suffix}`;
    },
  },
  watch: {
    selectedCounty(newVal, oldVal) {
      const newEl = this.$refs[`county-${newVal}`];
      const oldEl = this.$refs[`county-${oldVal}`];

      if (newEl) {
        const el = newEl[0];
        el.mapObject.setStyle({ color: 'white', weight: 2 });
        el.mapObject.bringToFront();
      }
      if (oldEl) {
        const el = oldEl[0];
        el.mapObject.setStyle({ color: 'black', weight: 1 });
      }
    },
    selectedHouseTX(newVal, oldVal) {
      const newEl = this.$refs[`houseTX-${newVal}`];
      const oldEl = this.$refs[`houseTX-${oldVal}`];

      if (newEl) {
        const el = newEl[0];
        el.mapObject.setStyle({ color: 'white', weight: 2 });
        el.mapObject.bringToFront();
      }
      if (oldEl) {
        const el = oldEl[0];
        el.mapObject.setStyle({ color: 'black', weight: 1 });
      }
    },
    selectedHouseUS(newVal, oldVal) {
      const newEl = this.$refs[`houseUS-${newVal}`];
      const oldEl = this.$refs[`houseUS-${oldVal}`];

      if (newEl) {
        const el = newEl[0];
        el.mapObject.setStyle({ color: 'white', weight: 2 });
        el.mapObject.bringToFront();
      }
      if (oldEl) {
        const el = oldEl[0];
        el.mapObject.setStyle({ color: 'black', weight: 1 });
      }
    },
    selectedSenateTX(newVal, oldVal) {
      const newEl = this.$refs[`senateTX-${newVal}`];
      const oldEl = this.$refs[`senateTX-${oldVal}`];

      if (newEl) {
        const el = newEl[0];
        el.mapObject.setStyle({ color: 'white', weight: 2 });
        el.mapObject.bringToFront();
      }
      if (oldEl) {
        const el = oldEl[0];
        el.mapObject.setStyle({ color: 'black', weight: 1 });
      }
    },
  },
};
</script>

<style scoped>
.header {
  padding: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
</style>
