var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header",style:({height: _vm.mobileMode ? '30px' : '60px'})},[_c('a',{staticStyle:{"text-decoration":"none","color":"#0099cc","margin-left":"8px"},attrs:{"href":"/changelog.html"}},[_vm._v(" Change Log ")]),_c('div',{staticStyle:{"display":"flex","flex-grow":"1","justify-content":"center","align-items":"center","height":"100%"}},[_c('a',{staticStyle:{"height":"100%"},attrs:{"href":"https://poweralliance.org","target":"_blank"}},[_c('img',{style:({
            height: _vm.mobileMode ? '60%' : '100%',
            'margin-right': '16px'
          }),attrs:{"src":require("@/assets/apa_logo.png")}})]),_c('a',{staticStyle:{"height":"100%"},attrs:{"href":"https://www.conservativetexansforenergyinnovation.org","target":"_blank"}},[_c('img',{style:({
            height: _vm.mobileMode ? '60%' : '100%',
            'margin-right': '16px'
          }),attrs:{"src":require("@/assets/ctei_logo.png")}})]),_c('a',{staticStyle:{"height":"100%"},attrs:{"href":"https://www.txbiz.org","target":"_blank"}},[_c('img',{style:({
            height: _vm.mobileMode ? '60%' : '100%',
            'margin-right': '16px'
          }),attrs:{"src":require("@/assets/tab_logo.jpg")}})])])]),_c('div',[_c('div',{style:({
      position: 'absolute',
      width: _vm.mobileMode ? '20%' : '25%',
      'font-size': _vm.mobileMode ? '10px' : false,
      'z-index': 9000,
      right: 0,
      background: '#d5d5d5',
      'text-align': 'left',
      padding: _vm.mobileMode ? '10px' : '16px',
      'border-radius': '8px',
      transform: 'translate(-14px, 70px)'
    })},[_c('label',{style:({
        'font-weight': 600,
        'font-size': _vm.mobileMode ? '10px' : '13px'
        })},[_vm._v("Choose one:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.region),expression:"region"}],style:({
        width: '100%',
        'margin-bottom': '16px',
        'font-size': _vm.mobileMode ? '9px' : false
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.region=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"senateTX"}},[_vm._v("TX Senate")]),_c('option',{attrs:{"value":"houseTX"}},[_vm._v("TX House")]),_c('option',{attrs:{"value":"county"}},[_vm._v("TX County")]),_c('option',{attrs:{"value":"houseUS"}},[_vm._v("US House")]),_c('option',{attrs:{"value":"senateUS"}},[_vm._v("US Senate (Statewide)")])]),_c('label',{style:({
        'font-weight': 600,
        'font-size': _vm.mobileMode ? '10px' : '13px'
        })},[_vm._v("Choose tech:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tech),expression:"tech"}],style:({
        width: '100%',
        'margin-bottom': '16px',
        'font-size': _vm.mobileMode ? '9px' : false
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.tech=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"wind"}},[_vm._v("Wind")]),_c('option',{attrs:{"value":"solar"}},[_vm._v("Solar")]),_c('option',{attrs:{"value":"storage"}},[_vm._v("Storage")])]),_c('label',{style:({
        'font-weight': 600,
        'font-size': _vm.mobileMode ? '10px' : '13px'
        })},[_vm._v("Choose one:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.buildStatus),expression:"buildStatus"}],style:({
        width: '100%',
        'margin-bottom': '16px',
        'font-size': _vm.mobileMode ? '9px' : false
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.buildStatus=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"built"}},[_vm._v("Built")]),_c('option',{attrs:{"value":"built+expected"}},[_vm._v("Built + Expected")]),_c('option',{attrs:{"value":"expected"}},[_vm._v("Expected")])]),_c('label',{style:({
        'font-weight': 600,
        'font-size': _vm.mobileMode ? '10px' : '13px'
        })},[_vm._v("Choose one:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pricing),expression:"pricing"}],style:({
        width: '100%',
        'margin-bottom': '16px',
        'font-size': _vm.mobileMode ? '9px' : false
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.pricing=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"cap"}},[_vm._v("Capacity (MW)")]),_c('option',{attrs:{"value":"taxes"}},[_vm._v("Local Taxes (est. $M)")]),_c('option',{attrs:{"value":"landowner"}},[_vm._v("Landowner Payments (est. $M)")])]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.region !== 'senateUS'),expression:"region !== 'senateUS'"}],style:({
        'font-weight': 600,
        'font-size': _vm.mobileMode ? '10px' : '13px'
        })},[_vm._v("Find a "+_vm._s(_vm.region === 'county' ? 'county' : 'district'))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRegion[_vm.region]),expression:"selectedRegion[region]"},{name:"show",rawName:"v-show",value:(_vm.region !== 'senateUS'),expression:"region !== 'senateUS'"}],style:({
        width: '100%',
        'margin-bottom': '16px',
        'font-size': _vm.mobileMode ? '9px' : false
        }),on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.selectedRegion, _vm.region, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select one")]),_vm._l((_vm.activeData),function(zone){return _c('option',{key:zone.id,domProps:{"value":zone.id}},[_vm._v(" "+_vm._s(zone.properties.name)+_vm._s(_vm.region === 'county' ? '' : ', ' + _vm.getRegionRepInfo(zone))+" ")])})],2),_c('a',{attrs:{"href":"https://wrm.capitol.texas.gov/home","target":"_blank"}},[_vm._v(" Look up a Lawmaker/District ")])]),_c('Legend',{style:({
        position: 'absolute',
        'z-index': 9000,
        left: 0,
        transform: 'translate(14px, 140%)',
        'font-size': _vm.mobileMode ? '9px' : (_vm.laptopMode ? '13px' : false),
        width : _vm.mobileMode ? '80px' : '120px'
      }),attrs:{"title":_vm.tech.substr(0, 1).toUpperCase() + _vm.tech.substr(1) + ' '
        + (_vm.pricing === 'cap' ? '(MW)' : '($M)'),"colors":_vm.colors}}),(_vm.mapHeight)?_c('LMap',_vm._b({style:({ width: '100%', height: _vm.mapHeight })},'LMap',_vm.mapAttrs,false),_vm._l((_vm.activeData),function(zone){return _c('LPolygon',_vm._b({key:zone.id,ref:(_vm.region + "-" + (zone.id)),refInFor:true,attrs:{"color":"black","fill-color":_vm.getColor(_vm.getCost(zone, _vm.tech)),"lat-lngs":zone.geometry.coordinates},on:{"click":function($event){return _vm.onClick(zone)},"mouseover":function($event){return _vm.mouseover($event)},"mouseout":function($event){return _vm.mouseout($event, zone)}}},'LPolygon',_vm.polygonAttrs,false),[_c('LTooltip',[_vm._v(" "+_vm._s(zone.properties.name)),_c('br'),_vm._v(" "+_vm._s(_vm.getRegionRepInfo(zone))),(_vm.getRegionRepInfo(zone))?_c('br'):_vm._e(),_vm._v(" Wind: "+_vm._s(_vm.getTooltipData(zone, 'wind'))),_c('br'),_vm._v(" Solar: "+_vm._s(_vm.getTooltipData(zone, 'solar'))),_c('br'),_vm._v(" Storage: "+_vm._s(_vm.getTooltipData(zone, 'storage'))),_c('br')])],1)}),1):_vm._e()],1),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.clickedRegion),expression:"clickedRegion"}],on:{"close":function($event){_vm.clickedRegion = null; _vm.modalDownloadAvailable = 'yes'}}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.modalHeaderContent))]),_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.modalDownloadAvailable === 'loading')?_c('p',[_vm._v("Checking for projects...")]):_vm._e(),(!_vm.modalDownloadAvailable)?_c('p',[_vm._v("No Projects for this region")]):_vm._e(),(_vm.modalDownloadAvailable === 'error')?_c('p',[_vm._v(" Error loading project data. Please try again in a moment. ")]):_vm._e(),(_vm.modalDownloadAvailable === 'yes')?_c('a',{attrs:{"href":_vm.modalHref,"download":_vm.modalDownload}},[_vm._v(" "+_vm._s(_vm.modalLinkText)+" ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }