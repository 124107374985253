<template lang="html">
  <div>
    <h1>Version 1.0</h1>
    <ul>
      <li>ERCOT existing capacity data from Preliminary Spring 2021 SARA report</li>
      <li>ERCOT expected future capacity from 2020 October Capacity Changes by Fuel Type</li>
      <li>Non-ERCOT Texas current and future capacity from EIA 860M (September 2020)</li>
      <li>
        Taxes and Landowner payment estimates from:
        <a href="https://www.ideasmiths.net/wp-content/uploads/2020/08/CTEI_PT_TX_renewable_county_analysis_FINAL_20200805.pdf">
          The Economic Impact of Renewable Energy in Rural Texas
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
</style>
